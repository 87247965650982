import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import Contact from "../Contact/Contact";
import avlogo from "../../staticAssets/av-logo.png";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/X";

const footerCard = {
  display: "flex",
  flexDirection: "column",
  width: "20vw",
  textAlign: "left",
  fontSize: "0.8rem",
  padding: "20px 40px 20px 0",
  paddingLeft: "0",
};

const buttonCss = {
  width: "15vw",
  background: "#f3f0dc",
  marginBottom: "5px",
  border: "solid 1px black",
  borderRadius: "4px",
  ":hover": {
    background: "white",
    cursor: "pointer",
  },
};

const Footer = () => {
  const thisYear = new Date().getFullYear();

  // open Contact form
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const callMe = () => (window.location.href = "tel:07478659955");
  return (
    <Box
      sx={{
        position: "absolute",
        height: "350px",
        width: "100%",
        backgroundColor: "#56001e",
        fontFamily: "Montserrat, sans-serif",
        bottom: "0px",
        color: "cornsilk",
        borderTop: "6px solid #ff6600",
        "& a": {
          color: "#e9e8e8",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          margin: "auto",
          width: "80%",
        }}
      >
        <Box sx={footerCard}>
          <div>
            <img
              style={{
                display: "inline-block",
                width: "50px",
                backgroundColor: "white",
                borderRadius: "50%",
              }}
              src={avlogo}
              alt="Andras Vargas logo"
              width="50"
            />
            <h5 style={{ display: "inline-block", width: "100px" }}>About</h5>
          </div>
          <p>I offer freelance services in the intersection of digital marketing and web development</p>
        </Box>
        <Box sx={footerCard}>
          <h5>Links</h5>
          <Link to="/">Homepage</Link>
          <Link to="/about">About</Link>
          <Link to="/technical-seo-audit-service">Technical SEO</Link>
          <Link to="/how-to-exclude-myself-from-google-analytics-4">Exclude traffic GTM</Link>
          <a href="https://floatingpint.com/seo-tool/">SEO Metadata Tool</a>
        </Box>
        <Box sx={footerCard}>
          <h5>Social</h5>
          <a href="https://www.linkedin.com/in/andrasvargas/" aria-label="LinkedIn">
            <LinkedInIcon sx={{ fontSize: 24, color: "white", marginRight: 1 }} />
          </a>
          <a href="https://www.facebook.com/andreasvargas07/" aria-label="Facebook">
            <FacebookIcon sx={{ fontSize: 24, color: "white", marginRight: 1 }} />
          </a>
          <a href="https://x.com/andreas_vargas_" aria-label="X (Twitter)">
            <TwitterIcon sx={{ fontSize: 24, color: "white", marginRight: 1 }} />
          </a>
        </Box>
        <Box sx={footerCard}>
          <h5>Contact</h5>
          <Box component="button" sx={buttonCss} onClick={callMe} aria-label="Call me">
            &#9742;&#65039; 0747 865 9955
          </Box>
          <Box component="button" sx={buttonCss} onClick={handleClickOpen} aria-label="Email me">
            &#128231; E-mail me...
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          margin: "auto",
          justifyContent: "center",
          gap: "10px",
          width: "80%",
        }}
      >
        <a
          href="https://app-eu1.hubspot.com/academy/achievements/dc0b5yy2/en/1/andras-vargyas/hubspot-cms-for-developers"
          title="HubSpot CMS for Developers"
        >
          <img
            src="https://hubspot-credentials-na1.s3.amazonaws.com/prod/badges/user/db101c2adf4542759114ffa19f120128.png"
            alt="HubSpot CMS for Developers Badge"
            width="180"
          />
        </a>
        <a
          href="https://app-eu1.hubspot.com/academy/achievements/xqn5c8jh/en/1/andras-vargyas/hubspot-cms-for-developers-ii-best-practices"
          title="HubSpot CMS for Developers II: Best Practices"
        >
          <img
            src="https://hubspot-credentials-na1.s3.amazonaws.com/prod/badges/user/2e0bae7f5b464be98a64bf89ec507f36.png"
            width="180"
            alt="HubSpot CMS for Developers II: Best Practices"
          />
        </a>
        <a
          href="https://app-eu1.hubspot.com/academy/achievements/2kbmp3jx/en/1/andras-vargyas/integrating-with-hubspot-i-foundations"
          title="Integrating With HubSpot I: Foundations"
        >
          <img
            src="https://hubspot-credentials-na1.s3.amazonaws.com/prod/badges/user/fa9cfb19c60d4c83ae1e434886ab6055.png"
            width="180"
            alt="Integrating With HubSpot I: Foundations"
          />
        </a>
        <a href="https://app-eu1.hubspot.com/academy/achievements/60xf3145/en/1/andras-vargyas/revenue-operations" title="Revenue Operations">
          <img
            src="https://hubspot-credentials-na1.s3.amazonaws.com/prod/badges/user/fc146a0069454db9ad8ea0aa02abfa6f.png"
            width="180"
            alt="Revenue Operations"
          />
        </a>
      </Box>
      <Box
        sx={{
          fontSize: "0.8rem",
          paddingBottom: "1px",
          textAlign: "center",
          paddingLeft: "10vw",
        }}
      >
        <p>Copyright &copy; {thisYear} Andras Vargyas t/as Andras Vargas. All rights reserved.</p>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {<Contact onClose={handleClose} />}
      </Dialog>
    </Box>
  );
};

export default Footer;
